import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Button from '../../components/common/Button';
import ContentContainer from '../../components/common/ContentContainer';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';

interface HeroSectionProps {}
const HeroSection: React.FC<HeroSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <section className="self-center w-3/4 mt-8">
        <h1
          className="mb-8 text-4xl font-bold text-center text-primary"
          id="our-story"
        >
          Our Story
        </h1>

        {/* <div className="self-center mt-10 mb-10 text-center">
          <Link to="our-story">
            <Button text="Read More" size="lg" />
          </Link>
        </div> */}
      </section>
    </section>
  );
};

interface WhatWeDoProps {}
const WhatWeDo: React.FC<WhatWeDoProps> = props => {
  return (
    <section className="flex flex-col bg-primary">
      <section className="self-center w-3/4 mt-8">
        <h2 className="mt-4 mb-8 text-4xl font-bold text-center text-light_green">
          What We Do
        </h2>
        <p className="self-center w-full m-auto text-xl text-light_green smw-2/3">
          Mossflower RWP provides state-of-the-art professional development to
          K-8 educators worldwide on reading, writing, and phonics. We offer
          on-site and virtual professional development to schools and districts,
          3-5-day institutes, conference days, study groups, and a plethora of
          resources for K-8 educators.
          <br />
          <br />
          We also develop curriculum to support best practices in literacy
          instruction. Calkins and her colleagues are the authors of major
          publications in the field of literacy education, including the New
          York Times best-seller, Pathways to the Common Core, and most recently
          the new decodable books for children, Jump Rope Readers. Units of
          Study in K-2 Reading and Writing have recently been released and Units
          of Study in 3-5 Writing will be out in Spring 2023.
        </p>
      </section>
    </section>
  );
};

interface MissionSectionProps {}
const WhoWeAre: React.FC<MissionSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <div className="flex flex-col self-center w-3/4 mt-10">
        <h2 className="mb-8 text-4xl font-bold text-center text-primary">
          Who We Are
        </h2>

        <p className="self-center w-full m-auto text-xl text-gray-500 smw-2/3">
          Mossflower RWP, formerly known as The Reading and Writing Project, has
          been both a think tank and a provider of professional development for
          decades. The organization began more than 40 years ago when Lucy
          Calkins joined the faculty at Teachers College, Columbia University,
          and founded both Teachers College Reading and Writing Project, a New
          York City-based organization, and the Reading and Writing Project
          Network, the former name for this organization, one which supports
          schools across the nation and the world.
          <br />
          <br />
          Named after a series of children’s books that have enthralled millions
          of children, Mossflower RWP is dedicated to the mission of helping all
          children grow to be enthusiastic and proficient readers and writers.
        </p>
      </div>
    </section>
  );
};

interface ResultsSectionProps {
  imageOne: any;
  imageTwo: any;
  imageThree: any;
}
const ResultsSection: React.FC<ResultsSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <div className="flex flex-col self-center w-3/4 mt-10">
        <h2
          className="mb-2 text-4xl font-bold text-center text-primary"
          id="how-we-know-it-works"
        >
          How We Know It Works
        </h2>
        <section className="self-center w-full mt-10 mb-10">
          <div className="flex">
            <div className="w-1/2">
              <div className="flex flex-col items-end mr-2">
                <div className="w-96">
                  <Img
                    className="w-full mb-2 rounded-tl-lg"
                    fluid={props.imageOne.childImageSharp.sizes}
                  />
                </div>
                <div className="w-96">
                  <Img
                    className="w-full rounded-bl-lg"
                    fluid={props.imageTwo.childImageSharp.sizes}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="h-full">
                <Img
                  className="object-fill h-full rounded-tr-lg rounded-br-lg w-96"
                  fluid={props.imageThree.childImageSharp.sizes}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="mb-10">
          <p className="mb-6 text-xl text-gray-600">
            The results of our reading, writing, and phonics workshops are
            visible in classrooms and schools around the globe. The work
            students do - the essays they write, the TedTalks they present, the
            petitions they send, the book club conversations they participate in
            - are our results.
          </p>
          <p className="mb-6 text-xl text-gray-600">
            Student growth can also be measured through performance on state
            standardized ELA exams. A 2021 study by the American Institute of
            Research (AIR) studied students in 229 schools in New York City and
            Georgia and found that those who have learned within TCRWP's
            curriculum, the Units of Study, outperform students in both
            locations who were taught using other curricula. This was true for
            students in all subgroups, including students with IEPs and English
            Language Learners, and the results were more dramatic the longer a
            school participated in TCRWP's curriculum.
          </p>
          <p className="mb-6 text-xl text-gray-600">
            Many schools working with TCRWP and with Units of Study have been
            honored as Blue Ribbon recipients, as Beat-the-Odds Schools, and as
            mentor sites.
          </p>
          <div className="flex justify-center text-xl">
            <Link
              to={`/resources/case-studies-and-reports`}
              className="text-blue-500 hover:underline"
            >
              <Button
                text="Learn more about our results in partner schools"
                className="text-center"
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

interface AffiliationSectionProps {}
const LearnWithUs: React.FC<AffiliationSectionProps> = props => {
  return (
    <section className="flex flex-col">
      <div className="flex flex-col self-center w-3/4 mt-10">
        <h2 className="mt-4 mb-8 text-4xl font-bold text-center text-primary">
          How You Can Learn With Us
        </h2>
        <p className="self-center w-full m-auto text-xl text-gray-500 smw-2/3">
          There are many avenues for studying with us. For over 40 years,
          Calkins has led two free Saturday conferences every year–one in late
          October and one in mid-March. More than 4,000 people choose among the
          200 virtual workshops and keynotes we offer on these days. On
          Wednesday evenings, several hundred educators participate in an event
          called Supper Club in which we hold informal, intimate exchanges with
          great literacy leaders in the world. Lucy also leads{' '}
          <a
            href="https://heinemann.zoom.us/webinar/register/WN_z182QXzqSk-arU1L_NIHMA#/registration"
            className="text-blue-500 underline cursor-pointer"
          >
            office hours
          </a>{' '}
          at 4:00 PM EST on the first Thursday of every month.
          <br />
          <br />
          Most of all, schools ask to work closely with our staff. Sometimes
          this work begins with a single day of professional development–these
          are usually called Quick Start Days–or with teachers attending a
          one-day conference or a summer institute and returning home, full of
          hopes for a more personalized and site-based involvement.
          <br />
          <br />
          Educators also learn from the curriculum,{' '}
          <a
            href="https://www.unitsofstudy.com/"
            className="text-blue-500 underline cursor-pointer"
          >
            Units of Study
          </a>
          , as it is designed to not only support children, but to also support
          those who work with children. There are Facebook communities of
          educators helping each other to implement the{' '}
          <a
            href="https://www.facebook.com/groups/TCRWP.RUOS"
            className="text-blue-500 underline cursor-pointer"
          >
            Units of Study in Reading
          </a>{' '}
          and{' '}
          <a
            href="https://www.facebook.com/groups/TCRWP.WUOS"
            className="text-blue-500 underline cursor-pointer"
          >
            Units of Study in Writing
          </a>{' '}
          .
        </p>
      </div>
    </section>
  );
};

interface AboutProps {}
const About: React.FC<AboutProps> = props => {
  const data = useStaticQuery(graphql`
    {
      imageOne: file(relativePath: { eq: "images/about-us-2.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      imageTwo: file(relativePath: { eq: "images/about-us-1.jpeg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      imageThree: file(relativePath: { eq: "images/about.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" />
      {/* <ContentContainer>
        <HeroSection />
      </ContentContainer> */}
      <div className="bg-gray-100">
        <ContentContainer>
          <WhoWeAre />
        </ContentContainer>
      </div>
      <div className="bg-primary">
        <ContentContainer>
          <WhatWeDo />
        </ContentContainer>
      </div>
      <div className="bg-gray-100">
        <ContentContainer>
          <LearnWithUs />
        </ContentContainer>
      </div>

      {/* <div className="bg-gray-50">
        <ContentContainer>
          <ResultsSection
            imageOne={data.imageOne}
            imageTwo={data.imageTwo}
            imageThree={data.imageThree}
          />
        </ContentContainer>
      </div> */}
    </Layout>
  );
};

export default About;
